import React from "react"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const NotFound = () => {
  return (
    <Layout>
      <SEO title="404" nofollow noindex openGraph={undefined} />
      <Section className="flex flex-col items-center justify-center flex-1">
        <h1 className="font-bebasNeue text-5xl">404</h1>
        <p className="tracking-3px mb-6 text-lg">Page not found!</p>
        <Link
          to="/"
          className="md:w-64 focus:outline-none focus:shadow-lg hover:shadow-lg focus:-translate-y-1 hover:-translate-y-1 block w-full py-4 mb-6 text-lg font-bold tracking-widest text-center capitalize transition duration-300 ease-out transform bg-red-400 rounded-lg shadow-md"
        >
          Go back home
        </Link>
      </Section>
    </Layout>
  )
}

export default NotFound
